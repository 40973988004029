import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import MDTypography from "material-ui/components/MDTypography";
import { useState } from "react";
import { Grid, Paper } from "@mui/material";
import RadioSelectWrapper from "components/radio-select-wrapper";
import { productCategories, products } from "helpers/products";
import { IProduct, IProductCategory } from "models/product";
import SubscriptionsComparison from "./components/subscriptions-comparison";
import SubscriptionsPricingTable from "./components/subscriptions-pricing-table";
import { addOnsTable, pricingPlansTable, subscriptionOptions } from "./helper/subscriptionsUtils";

const Subscriptions = () => {
  const [product, setProduct] = useState<IProduct>(products[0])
  const [productCategory, setProductCategory] = useState<IProductCategory>(productCategories[0])

  const renderSubscriptionOption = (title: string | JSX.Element, image: string, index: number) => (
    <Grid key={index} item xs={4}>
      <RadioSelectWrapper selected={productCategory?.id === index} onClick={() => {
        let newCategory = productCategories[index]
        let newProduct = products.find((a) => a.id === newCategory.products[0])

        setProductCategory(newCategory)
        setProduct(newProduct!)
      }}>
        <Grid p={1} gap={1} container justifyContent="center" alignItems="center">
          <Grid item>
            <img alt="" src={image} />
          </Grid>
          <Grid item>
            <MDTypography variant="h5" fontWeight="bold">{title}</MDTypography>
          </Grid>
        </Grid>
      </RadioSelectWrapper>
    </Grid>
  )

  return (
    <MDCard boxShadow>
      <MDBox p={4} display="flex" justifyContent="center" width="100%">
        <MDBox width="100%" maxWidth="1200px">

          <SubscriptionsComparison title="Pricing Plan Comparison" table={pricingPlansTable} firstColumnSize={3.5} />

          <MDTypography mt={6} fontWeight="bold" sx={{ fontSize: "36px" }} color={'secondary'}>
            Subscription Pricing Tiers
          </MDTypography>

          <MDBox mt={2} display="flex" justifyContent="center">
            <Paper elevation={8} sx={{ borderRadius: 4, maxWidth: "850px", display: "block", width: "100%" }}>
              <MDBox p={1}>
                <Grid container alignItems="center" justifyContent="center" gap={1} flexWrap="nowrap">
                  {subscriptionOptions.map(({ title, image }, index) => renderSubscriptionOption(title, image, index))}
                </Grid>

                <Grid container mt={1} alignItems="center" justifyContent="center" gap={1}>
                  {productCategory?.products.map((a) => {
                    let p = products.find(x => x.id === a)

                    return <Grid item xs key={p?.id}>
                      <RadioSelectWrapper selected={p?.id === product?.id}
                        onClick={() => setProduct(p!)}>
                        <MDBox>
                          <MDTypography variant="h5"
                            textAlign="center">{p?.label}</MDTypography>
                        </MDBox>
                      </RadioSelectWrapper>
                    </Grid>
                  })}
                </Grid>
              </MDBox>
            </Paper>
          </MDBox>

          <SubscriptionsPricingTable product={product} />

          <MDBox mt={2} mb={4}>
            <MDTypography textAlign="center" sx={{ fontSize: 16 }} color="text">
              * Exact amount depends on mix of products and options selected.
              <MDTypography component="span" color="primary" fontWeight="bold" display="inline-block" sx={{ fontSize: 16 }}>
                View product details
              </MDTypography>
            </MDTypography>
          </MDBox>

          <SubscriptionsComparison title="Add Ons" table={addOnsTable} firstColumnSize={5.5} />

        </MDBox>
      </MDBox>
    </MDCard>
  )
}

export default Subscriptions
