import { ProductTypes } from 'models/enums/ProductTypes';
import { SceneBlockNames } from 'models/enums/SceneBlockNames';
import { BlockNames, DefaultArtifacts } from 'newStandard/src/features/Editor/utils/sceneEnums';

export const setBlockToImageByName = (creativeEngine, blockName, url) => {
  if (!creativeEngine) {
    return;
  }
  creativeEngine.block.findByName(blockName).forEach((blockId) => {
    setBlockToImageById(creativeEngine, blockId, url);
  });
};

export const setBlockToImageById = (creativeEngine, blockId, url) => {
  if (!creativeEngine) {
    return;
  }

  let imageFill = creativeEngine.block.getFill(blockId);
  const isValid = creativeEngine.block.isValid(imageFill);
  if (!isValid) imageFill = creativeEngine.block.createFill('image');
  let existingUrl = creativeEngine.block.getString(imageFill, 'fill/image/imageFileURI');

  if (url.includes(existingUrl)) {
    creativeEngine.block.setString(imageFill, 'fill/image/imageFileURI', url);
    creativeEngine.block.setPlaceholderControlsOverlayEnabled(blockId, true);
    creativeEngine.block.setPlaceholderControlsButtonEnabled(blockId, false);
    creativeEngine.block.setPlaceholderEnabled(blockId, false);

    // Fits the image to the block - scaling it to the height of the block
    // Documentation: https://img.ly/docs/cesdk/engine/api/block-crop/?platform=node#crop
    creativeEngine.block.setContentFillMode(blockId, 'Contain');
  }
};

export const removeBlock = (creativeEngine, blockId) => {
  if (!creativeEngine) {
    return;
  }

  try {
    creativeEngine.block.setScopeEnabled(blockId, 'lifecycle/destroy', true);
    creativeEngine.block.destroy(blockId);
  } catch (ex) {}
};

export const addTrackablePhoneNumber = (creativeEngine, currentPageBlockId, value) => {
  const block = creativeEngine.block.create('text');

  creativeEngine.block.setFloat(block, 'text/fontSize', 20);
  creativeEngine.block.setHeightMode(block, 'Auto');
  creativeEngine.block.replaceText(block, value);

  creativeEngine.block.setPlaceholderEnabled(block, false);

  const pageWidth = creativeEngine.block.getWidth(currentPageBlockId);
  creativeEngine.block.setWidth(block, pageWidth * 0.5);
  autoPlaceBlockOnPage(creativeEngine, currentPageBlockId, block);
};

export const addImagePlaceholder = function (
  engine,
  parentId,
  positionX,
  positionY,
  width,
  height,
  blockName = BlockNames.Image,
  sceneDefaultImage = DefaultArtifacts.OutsideFront
) {
  if (!engine) {
    return;
  }

  try {
    const block = engine.block.create('graphic');
    const rectShape = engine.block.createShape('rect');
    const imageFill = engine.block.createFill('image');
    engine.block.setString(imageFill, 'fill/image/imageFileURI', sceneDefaultImage);

    engine.block.setBool(block, 'placeholderControls/showButton', true);
    engine.block.setBool(block, 'placeholderControls/showOverlay', true);
    engine.block.setBool(block, 'placeholder/enabled', true);

    engine.block.setName(block, blockName);

    engine.block.setPositionX(block, positionX);
    engine.block.setPositionY(block, positionY);
    engine.block.setHeightMode(block, 'Absolute');
    engine.block.setHeight(block, height);
    engine.block.setWidthMode(block, 'Absolute');
    engine.block.setWidth(block, width);

    engine.block.setShape(block, rectShape);
    engine.block.setFill(block, imageFill);
    engine.block.setKind(block, 'image/placeholder');
    engine.block.appendChild(parentId, block);

    console.log('Image placeholder added successfully');
  } catch (error) {
    console.error('Error adding image placeholder:', error);
  }
};

// Appends a block into the scene and positions it somewhat randomly.
const autoPlaceBlockOnPage = (
  engine,
  page,
  block,
  config = {
    basePosX: 0.25,
    basePosY: 0.25,
    randomPosX: 0.05,
    randomPosY: 0.05,
  }
) => {
  engine.block.findAllSelected().forEach((blockId) => engine.block.setSelected(blockId, false));
  engine.block.appendChild(page, block);

  const pageWidth = engine.block.getWidth(page);
  const posX = pageWidth * (config.basePosX + Math.random() * config.randomPosX);
  engine.block.setPositionXMode(block, 'Absolute');
  engine.block.setPositionX(block, posX);

  const pageHeight = engine.block.getWidth(page);
  const posY = pageHeight * (config.basePosY + Math.random() * config.randomPosY);
  engine.block.setPositionYMode(block, 'Absolute');
  engine.block.setPositionY(block, posY);

  engine.block.setSelected(block, true);
  engine.editor.addUndoStep();
};

export function createBleedLine(creativeEngine, parentId, color, widthOffset, heightOffset, posX, posY) {
  if (!creativeEngine) {
    return;
  }

  const block = creativeEngine.block.create('graphic');
  const rect = creativeEngine.block.createShape('rect');

  creativeEngine.block.setShape(block, rect);

  creativeEngine.block.setStrokeEnabled(block, true);
  creativeEngine.block.setStrokeWidth(block, 0.01);
  creativeEngine.block.setStrokeColor(block, color);
  creativeEngine.block.setStrokeStyle(block, 'LongDashed');

  let pageWidth = creativeEngine.block.getWidth(parentId);
  let pageHeight = creativeEngine.block.getHeight(parentId);

  creativeEngine.block.setWidth(block, pageWidth + widthOffset);
  creativeEngine.block.setHeight(block, pageHeight + heightOffset);
  creativeEngine.block.setPositionX(block, posX);
  creativeEngine.block.setPositionY(block, posY);

  creativeEngine.block.setName(block, SceneBlockNames.SafeZone);
  creativeEngine.block.setIncludedInExport(block, false);

  creativeEngine.block.setScopeEnabled(block, 'editor/select', false);

  creativeEngine.block.setVisible(block, true);
  creativeEngine.block.setAlwaysOnTop(block, true);

  creativeEngine.block.appendChild(parentId, block);
}

export function createBleedText(creativeEngine, parentId, text, color, fontSize, xOffset, yOffset, blockName) {
  if (!creativeEngine) {
    return;
  }

  const block = creativeEngine.block.create('text');

  let pageHeight = creativeEngine.block.getHeight(parentId);
  // const currentTypeface = creativeEngine.block.getTypeface(block);
  // creativeEngine.block.setFont(block, "extensions/ly.img.cesdk.fonts/fonts/Archivo/static/Archivo/Archivo-Regular.ttf", currentTypeface);
  creativeEngine.block.setFloat(block, 'text/fontSize', fontSize);
  creativeEngine.block.setEnum(block, 'text/horizontalAlignment', 'Left');
  creativeEngine.block.setName(block, blockName);
  creativeEngine.block.setTextColor(block, color);
  creativeEngine.block.replaceText(block, text);

  creativeEngine.block.setPositionX(block, 0 + xOffset);
  creativeEngine.block.setPositionY(block, pageHeight + yOffset);

  creativeEngine.block.setScopeEnabled(block, 'editor/select', false);
  creativeEngine.block.setIncludedInExport(block, false);

  creativeEngine.block.setVisible(block, true);

  creativeEngine.block.appendChild(parentId, block);
}

export function addBorderToPage(creativeEngine, pageId) {
  if (!creativeEngine) {
    return;
  }

  const block = creativeEngine.block.create('graphic');
  const rect = creativeEngine.block.createShape('rect');

  creativeEngine.block.setShape(block, rect);

  creativeEngine.block.setStrokeEnabled(block, true);
  creativeEngine.block.setStrokeWidth(block, 0.01);
  creativeEngine.block.setStrokeColor(block, { r: 0.4, g: 0.4, b: 0.4, a: 1 });
  creativeEngine.block.setStrokeStyle(block, 'Solid');

  let pageWidth = creativeEngine.block.getWidth(pageId);
  let pageHeight = creativeEngine.block.getHeight(pageId);

  creativeEngine.block.setWidth(block, pageWidth);
  creativeEngine.block.setHeight(block, pageHeight);
  creativeEngine.block.setPositionX(block, 0);
  creativeEngine.block.setPositionY(block, 0);

  creativeEngine.block.setName(block, SceneBlockNames.Border);
  creativeEngine.block.setIncludedInExport(block, false);

  creativeEngine.block.setScopeEnabled(block, 'editor/select', false);

  creativeEngine.block.appendChild(pageId, block);
}

export function stretchImageToFullBleed(creativeEngine, blockId, product) {
  if (!creativeEngine) {
    return;
  }

  const isBifold = product === ProductTypes.HandwrittenBiFoldCard;
  const currentPage = creativeEngine.scene.getCurrentPage();

  let pageWidth = creativeEngine.block.getWidth(currentPage);
  let pageHeight = creativeEngine.block.getHeight(currentPage);

  creativeEngine.block.setWidth(blockId, pageWidth + 0.2);
  creativeEngine.block.setHeight(blockId, (isBifold ? pageHeight / 2 : pageHeight) + 0.2);
  creativeEngine.block.setPositionX(blockId, -0.1);
  creativeEngine.block.setPositionY(blockId, isBifold ? 5.2 : -0.1);

  creativeEngine.block.setScopeEnabled(blockId, 'layer/crop', true);
}
