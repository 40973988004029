import API_ENDPOINT, { IObjectResult } from 'services/endpoint';
import useFetchWithMsal from 'hooks/useFetch';

import { conversionMocks } from './mocks';
import { IConversion } from './types';

const useConversions = () => {
  const { execute } = useFetchWithMsal();
  const API_PATH = `${API_ENDPOINT}/conversions`;
  const shouldMock = true;

  const getConversion = (): Promise<IObjectResult<IConversion[]>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: conversionMocks }));
    return execute({ endpoint: API_PATH, method: 'GET' });
  };

  const deleteConversionById = (conversionId: number): Promise<IObjectResult<{}>> => {
    if (shouldMock) return new Promise((resolve) => resolve({ payload: {} }));
    return execute({ endpoint: API_PATH, method: 'DELETE', data: { conversionId } });
  };

  return { getConversion, deleteConversionById };
};

export default useConversions;
