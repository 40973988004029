import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Grid, Typography } from '@mui/material';
import MDCard from 'material-ui/components/MDCard';
import useDataSourcesService from 'services/data-sources';
import SmartAutomationSetupItem from './components/smart-automation-setup-item';
import klaviyoLogo from 'assets/images/klaviyo-logo.png';
import leadRevealLogo from 'assets/images/lead-reveal-logo.png';
import ShopifyLogo from 'assets/images/shopify-logo.svg';
import accuLynxLogo from 'assets/images/acculynx-logo.jpg';
import { useGlobal } from 'context/global-context';
import moversLogo from 'assets/images/movers-logo.png';
import MDButton from 'material-ui/components/MDButton';
import PlusIcon from 'assets/icons/plus';
import NewSmartAutomationModal from 'components/new-smart-automation-modal';
import { AuthenticatedStatusType } from 'services/data-sources/data-sources.types';
import LeadRevealHelper from './components/lead-reveal/lead-reveal-helper';
import ShopifyModal from './components/shopify-modal';
import { isLettrLabsExternalIntegrationsUser, isLettrLabsUser } from 'helpers/profileHelper';
import ProfileService from 'services/profile';
import useSourceInstallRequest from 'hooks/useSourceInstallRequest';
import LeadRevealFinishModal from './components/lead-reveal/lead-reveal-finish/lead-reveal-finish-modal';
import LeadRevealManageModal from './components/lead-reveal/lead-reveal-manage/lead-reveal-manage-modal';
import LeadRevealSetupModal from './components/lead-reveal/lead-reveal-setup-modal';
import LeadRevealSuccessModal from './components/lead-reveal/lead-reveal-success-modal';
import LeadRevealRevokeModal from './components/lead-reveal/lead-reveal-revoke-modal';

export interface ISetupOption {
  name: string;
  logo: string;
  isEnabled?: boolean;
  authenticationStatus?: AuthenticatedStatusType;
  onManage?: () => void;
  onDisable?: () => void;
  onSetup?: () => void;
  onFinishSetup?: () => void;
  onRevoke?: () => void;
  isComingSoon?: boolean;
  learnMoreText?: ReactNode;
  learnMoreVideo?: string;
}

// Possible setup options:
// - Active with no action example
// - Active with manage action example
// - Active with disable action example
// - Active with no action + learn more text example
// - Active with manage action + learn more video example
// - Active with disable action + learn more text and video example
// - Inactive
// - Inactive + learn more
// - Coming soon

const SmartAutomationSetup = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();
  const { getProfile } = ProfileService();
  const { setInstallSource } = useSourceInstallRequest();
  const { getAllDataSources, revokeDataSource } = useDataSourcesService();
  const [searchParams, setSearchParams] = useSearchParams();
  const modal = searchParams.get('modal');

  const [smartAutomations, setSmartAutomations] = useState<ISetupOption[]>([]);

  const { data: profile } = useQuery({ queryKey: ['profile'], queryFn: getProfile });

  const { mutate: revoke, isPending } = useMutation({
    mutationFn: revokeDataSource,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['getAllDataSources'] }),
  });

  const {
    data: dataSourcesData,
    isLoading,
    isFetching,
    isRefetching,
  } = useQuery({ queryKey: ['getAllDataSources'], queryFn: () => getAllDataSources(), refetchOnWindowFocus: false });
  const dataSources = dataSourcesData?.payload;

  useEffect(() => {
    setShowLoader(isLoading || isFetching || isRefetching || isPending);
  }, [isFetching, isLoading, isRefetching, isPending, setShowLoader]);

  useEffect(() => {
    const setupOptions: ISetupOption[] = [
      {
        name: 'Klaviyo',
        logo: klaviyoLogo,
        onSetup: () => navigate('/oauth/setup/klaviyo'),
        onRevoke: () => revoke('klaviyo'),
      },
      { name: 'MoverMail', logo: moversLogo },
      {
        name: 'Lead Reveal',
        logo: leadRevealLogo,
        onSetup: () => setSearchParams('modal=SetupLeadReveal', { replace: true }),
        onFinishSetup: () => setSearchParams('modal=LeadReveal', { replace: true }),
        onManage: () => setSearchParams('modal=ManageLeadReveal', { replace: true }),
        learnMoreText: <LeadRevealHelper />,
      },
      {
        name: 'Shopify',
        logo: ShopifyLogo,
        onSetup: () => setInstallSource('shopify'),
        onManage: () => setSearchParams('modal=Shopify', { replace: true }),
        isComingSoon: !isLettrLabsUser(profile) && !isLettrLabsExternalIntegrationsUser(profile),
      },
      { name: 'AccuLynx', logo: accuLynxLogo, isComingSoon: true },
    ];

    const newOptions = setupOptions.map((so) => {
      if (!dataSources?.length) return so;
      const ds = dataSources.find((el) => el.displayName === so.name);
      return { ...so, isEnabled: ds?.isEnabled, authenticationStatus: ds?.authenticationStatus };
    });

    setSmartAutomations(newOptions);
  }, [dataSources, navigate, profile, revoke, setInstallSource, setSearchParams]);

  const handleCloseModal = () => setSearchParams('', { replace: true });

  return (
    <MDCard sx={{ p: 2 }}>
      <Grid container mb={2} alignItems={'center'}>
        <Grid item xs>
          <Typography variant="h4" fontSize="26px">
            Smart Automations Setup
          </Typography>
        </Grid>
        <Grid item container width={'max-content'} spacing={2}>
          {/* TODO: enable when content is available */}
          {/* <Grid item>
            <MDButton color="light">
              <HelpOutlineIcon sx={{ mr: 1 }} />
              Help me
            </MDButton>
          </Grid> */}
          <Grid item>
            <MDButton onClick={() => setSearchParams('modal=NewAutomation', { replace: true })}>
              <PlusIcon style={{ marginRight: 8 }} /> New Automation
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {smartAutomations.map((option) => (
          <SmartAutomationSetupItem key={option.name} {...option} />
        ))}
      </Grid>
      <NewSmartAutomationModal open={modal === 'NewAutomation'} setOpen={handleCloseModal} />
      <LeadRevealSetupModal />
      <LeadRevealSuccessModal />
      <LeadRevealRevokeModal />
      <LeadRevealFinishModal open={modal === 'LeadReveal'} onClose={handleCloseModal} />
      <LeadRevealManageModal open={modal === 'ManageLeadReveal'} onClose={handleCloseModal} />
      <ShopifyModal open={modal === 'Shopify'} onClose={handleCloseModal} />
    </MDCard>
  );
};

export default SmartAutomationSetup;
