import { IConversion } from './types';

export const conversionMocks: IConversion[] = [
  {
    downloadLink: "https://example.com/downloads/document1.pdf",
    fileName: "document1.pdf",
    id: 1001,
    source: "user_upload",
    uploadDate: "2024-11-01T08:45:00Z",
  },
  {
    downloadLink: "https://example.com/downloads/report_final.docx",
    fileName: "report_final.docx",
    id: 1002,
    source: "system_generated",
    uploadDate: "2024-10-25T10:00:00Z",
  },
  {
    downloadLink: "https://example.com/downloads/image_2024.png",
    fileName: "image_2024.png",
    id: 1003,
    source: "user_upload",
    uploadDate: "2024-09-18T13:30:00Z",
  },
  {
    downloadLink: "https://example.com/downloads/budget_summary.xlsx",
    fileName: "budget_summary.xlsx",
    id: 1004,
    source: "shared_link",
    uploadDate: "2024-07-05T09:15:00Z",
  },
  {
    downloadLink: "https://example.com/downloads/presentation_final.pptx",
    fileName: "presentation_final.pptx",
    id: 1005,
    source: "user_upload",
    uploadDate: "2024-06-20T16:45:00Z",
  },
];
