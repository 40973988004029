import {  useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Grid, Paper } from "@mui/material";
import MDCard from "material-ui/components/MDCard";
import MDBox from "material-ui/components/MDBox";
import MDButton from "material-ui/components/MDButton";
import MDTypography from "material-ui/components/MDTypography";
import { IProduct } from "models/product";
import { ApiResponse } from "models/apiResponse";
import Product from "assets/images/products/product.png"
import ProductPricingService from "services/product";
import { formatNumber, formatNumberShort, formatPrice } from "helpers/formatters";
import MonthlyPricingTiersModal from "../monthly-pricing-tiers-modal";
import { useGlobal } from "context/global-context";
import { ProductTypes, orderProductIsPrintedCard } from "models/enums/ProductTypes";

interface ProductPricing {
  id: number
  pricingTiers: {
    price: number
    lowerBound: number
    upperBound: number
  }[]
  subscriptionPricings: {
    subscriptionPrice: number
    cardPrice: number
    cardsIncluded: number
  }[]
}

interface SubscriptionsPricingTableProps {
  product: IProduct;
}

const SubscriptionsPricingTable = ({ product }: SubscriptionsPricingTableProps) => {
  const { getProductPricing } = ProductPricingService()
  const { setShowLoader } = useGlobal()

  const [productPricings, setProductPricings] = useState<ProductPricing | null>(null)
  const [showMonthlyPricingTiersModal, setShowMonthlyPricingTiersModal] = useState<boolean>(false)

  const getProductPricingQuery = useQuery<ApiResponse<ProductPricing[]>>({
    queryFn: getProductPricing,
    queryKey: ["productPricing"],
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    setShowLoader(!!getProductPricingQuery.isLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductPricingQuery.isLoading]);

  useEffect(() => {
    if (getProductPricingQuery.data) {
      setProductPricings(getProductPricingQuery.data.payload.find(a => a.id === product.id) ?? null)
    }
  }, [product, getProductPricingQuery.data]);

  const renderTableHeader = (frequency: string, type: string) => (
    <Grid container alignItems={"center"} justifyContent={"space-between"} px={3}>
      <Grid item>
        <MDTypography sx={{ fontSize: 24, lineHeight: 1.2 }} fontWeight={"bold"} color={"secondary"}>
          {frequency}
        </MDTypography>
        <MDTypography sx={{ fontSize: 24, lineHeight: 1.2 }} fontWeight={"bold"} color={"primary"}>
          {type}
        </MDTypography>
        <MDBox mt={1}>
          <MDCard customColor={"#F05A2A1A"} border={false}
            sx={{ width: "fit-content" }}>
            <MDBox px={1} py={0.3}>
              <MDTypography sx={{ fontSize: 13 }} color={"primary"}>
                + Envelope
              </MDTypography>
            </MDBox>
          </MDCard>
        </MDBox>
      </Grid>
      <Grid item>
        <img src={Product} style={{
          width: "150px",
          height: "150px",
        }} alt={""} />
      </Grid>
    </Grid>
  )

  const renderLastCostPerPrintedPostcard = () => {
    if (product.name === ProductTypes.PrintedPostcard6x11) return "$0.80"
    if (product.name === ProductTypes.PrintedPostcard6x9) return "$0.70"
    return "$0.65"
  }

  if (!productPricings) return null;
  return (
    <MDBox mt={4}>
      <Grid container gap={3} justifyContent={"center"}>
        <Grid item width={"400px"}>
          <Paper elevation={8} sx={{ borderRadius: 4 }}>
            <MDBox position={"relative"}>
              {renderTableHeader("One Off", "Order Pricing")}

              <Grid container alignItems={"center"} justifyContent={"center"} sx={{
                borderTop: "1px solid #E4E6F1",
              }}>
                <Grid item flex={1} p={1.5} sx={{ borderRight: "1px solid #E4E6F1" }}>
                  <MDTypography align={"center"} fontWeight={"bold"} sx={{ fontSize: 14 }}>
                    Card Volume
                  </MDTypography>
                </Grid>

                <Grid item flex={1} p={1.5}>
                  <MDTypography align={"center"} fontWeight={"bold"} sx={{ fontSize: 14 }}>
                    Cost per card
                  </MDTypography>
                </Grid>
              </Grid>

              {productPricings?.pricingTiers.map((entry, index) => {
                return <Grid container alignItems={"center"} key={index} justifyContent={"center"} sx={{
                  borderTop: "1px solid #E4E6F1"
                }}>
                  <Grid item flex={1} p={1.5} sx={{ borderRight: "1px solid #E4E6F1" }}>
                    <MDTypography align={"center"} sx={{ fontSize: 15 }} fontWeight={"normal"}>
                      {`${formatNumberShort(entry.lowerBound + 1)} - ${formatNumberShort(entry.upperBound)}`}
                    </MDTypography>
                  </Grid>

                  <Grid item flex={1} p={1.5}>
                    <MDTypography align={"center"} sx={{ fontSize: 15 }} fontWeight={"normal"}>
                      {formatPrice(entry.price)}
                    </MDTypography>
                  </Grid>
                </Grid>
              })}

              <Grid container alignItems={"center"} justifyContent={"center"} sx={{
                borderTop: "1px solid #E4E6F1"
              }}>
                <Grid item flex={1} p={1.5} sx={{ borderRight: "1px solid #E4E6F1" }}>
                  <MDTypography align={"center"} sx={{ fontSize: 15 }} fontWeight={"normal"}>
                    {orderProductIsPrintedCard(product.name) ? "500+" : `${formatNumberShort(10000)}+`}
                  </MDTypography>
                </Grid>

                <Grid item flex={1} p={1.5}>
                  {orderProductIsPrintedCard(product.name) ? (
                    <MDTypography align={"center"} sx={{ fontSize: 15 }} fontWeight={"normal"}>
                      {renderLastCostPerPrintedPostcard()}
                    </MDTypography>
                  ) : (
                    <MDTypography align={"center"} color={"primary"} sx={{ fontSize: 15 }} fontWeight={"bold"}>
                      Enterprise Pricing
                    </MDTypography>
                  )}
                </Grid>
              </Grid>

              <MDBox p={2} sx={{ borderTop: "1px solid #E4E6F1" }}>
                <a href={"mailto: billing@lettrlabs.com"}>
                  <MDButton fullWidth>
                    Contact Us
                  </MDButton>
                </a>
              </MDBox>
            </MDBox>
          </Paper>
        </Grid>

        <Grid item width={"550px"}>
          <Paper elevation={8} sx={{ borderRadius: 4 }}>
            <MDBox position={"relative"}>
              {renderTableHeader("Monthly", "Pre-Pay Plans")}

              <Grid container alignItems={"center"} justifyContent={"center"} sx={{
                borderTop: "1px solid #E4E6F1",
              }}>
                <Grid item flex={1} p={1.5} sx={{ borderRight: "1px solid #E4E6F1" }}>
                  <MDTypography align={"center"} fontWeight={"bold"} sx={{ fontSize: 14 }}>
                    Monthly Pre-Pay
                  </MDTypography>
                </Grid>

                <Grid item flex={1} p={1.5} sx={{ borderRight: "1px solid #E4E6F1" }}>
                  <MDTypography align={"center"} fontWeight={"bold"} sx={{ fontSize: 14 }}>
                    Cost per Card
                  </MDTypography>
                </Grid>

                <Grid item flex={1} p={1.5}>
                  <MDTypography align={"center"} fontWeight={"bold"} sx={{ fontSize: 14 }}>
                    Cards Included
                  </MDTypography>
                </Grid>
              </Grid>

              {productPricings?.subscriptionPricings.map((entry, index) => {
                return <Grid container alignItems={"center"} key={index} justifyContent={"center"} sx={{
                  borderTop: "1px solid #E4E6F1"
                }}>
                  <Grid item flex={1} p={1.5} sx={{ borderRight: "1px solid #E4E6F1" }}>
                    <MDTypography align={"center"} sx={{ fontSize: 15 }} fontWeight={"normal"}>
                      ${formatNumber(entry.subscriptionPrice)}
                    </MDTypography>
                  </Grid>

                  <Grid item flex={1} p={1.5} sx={{ borderRight: "1px solid #E4E6F1" }}>
                    <MDTypography align={"center"} sx={{ fontSize: 15 }} fontWeight={"normal"}>
                      {formatPrice(entry.cardPrice)}
                    </MDTypography>
                  </Grid>

                  <Grid item flex={1} p={1.5}>
                    <MDTypography align={"center"} sx={{ fontSize: 15 }} fontWeight={"normal"}>
                      {formatNumber(entry.cardsIncluded)} Cards
                    </MDTypography>
                  </Grid>
                </Grid>
              })}

              <Grid container alignItems={"center"} justifyContent={"center"} sx={{
                borderTop: "1px solid #E4E6F1"
              }}>
                <Grid item flex={1} p={1.5} sx={{ borderRight: "1px solid #E4E6F1" }}>
                  <MDTypography align={"center"} sx={{ fontSize: 15 }} fontWeight={"bold"} color={"primary"}>
                    Enterprise Pricing
                  </MDTypography>
                </Grid>
              </Grid>

              <MDBox p={2} sx={{ borderTop: "1px solid #E4E6F1" }}>
                <MDButton fullWidth onClick={() => setShowMonthlyPricingTiersModal(true)}>
                  Sign Up
                </MDButton>
              </MDBox>
            </MDBox>
          </Paper>
        </Grid>
      </Grid>

      <MonthlyPricingTiersModal
        show={showMonthlyPricingTiersModal}
        setShow={setShowMonthlyPricingTiersModal}
      />
    </MDBox>
  )
}

export default SubscriptionsPricingTable;
